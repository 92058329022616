<template>
  <div>
    <div class="route-canvas">
      <canvas ref="mapCanvas"></canvas>
    </div>
  </div>
</template>

<script>
const iconImgs = [
  require(`@/assets/milestone-icon/drawer/mountain.png`),
  require(`@/assets/milestone-icon/drawer/parking.png`),
  require(`@/assets/milestone-icon/drawer/house.png`),
  require(`@/assets/milestone-icon/drawer/toilet.png`),
  require(`@/assets/milestone-icon/drawer/camp.png`),
  require(`@/assets/milestone-icon/drawer/walk.png`),
  require(`@/assets/milestone-icon/drawer/water.png`),
  require(`@/assets/milestone-icon/drawer/wifi.png`),
  require(`@/assets/milestone-icon/drawer/food.png`),
  require(`@/assets/milestone-icon/drawer/Logo_text.png`),
  require(`@/assets/milestone-icon/drawer/time.png`),
  require(`@/assets/milestone-icon/drawer/trending_up.png`),
  require(`@/assets/milestone-icon/drawer/trending_down.png`),
  require(`@/assets/milestone-icon/drawer/creat.png`),
];

export default {
  name: 'JourneyDrawer',
  data() {
    return {
      // canvas用
      canvasSize: {
        width: 100,
        height: 100,
      },
      // canvas 實際大小
      canvasStyleSize: {
        width: 1000,
        height: 1000,
      },
      canvasMapBounds: [100, 150, 800, 800],
      cxMin: 0,
      cxMax: 0,
      cyMin: 0,
      cyMax: 0,
      normalMilestones: [],
      normalRoutes: [],
      drawMilestones: [],
      drawRoutes: [],
      rotateAngle: 0,
      imgElements: [],

      inited: false,
    }
  },
  props: {
    size: {
      type: Number,
      required: false,
      default: 1000,
    },
    journey: {
      type: Object,
      required: true,
    },
    pathWidth: {
      type: Number,
      required: false,
      default: 0.02,
    },
    pathColor: {
      type: String,
      required: false,
      // default: "#e5dc81",
      default: "#F7DE5B",
    },
    needRotate: {
      type: Boolean,
      required: false,
      default: false,
    },
    dayIndex: {
      type: Number,
      required: false,
      default: -1,
    },
    hideBlankMilestones: {
      type: Boolean,
      required: false,
      default: false, 
    },
    config: {
      type: Object,
      required: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    showInfo: {
      type: Boolean,
      required: false,
      default: true,
    },
    showLogo: {
      type: Boolean,
      required: false,
      default: true,
    },
    milestoneTextColor: {
      type: String,
      required: false,
      default: "#F7DE5B",
    }
  },
  created() {
    if (this.showInfo) {
      this.canvasStyleSize.width = this.size;
      this.canvasStyleSize.height = this.size;
      this.canvasMapBounds = [this.size * 0.15, this.size * 0.25, this.size * 0.7, this.size * 0.7];
    } else {
      this.canvasStyleSize.width = this.size;
      this.canvasStyleSize.height = this.size;
      this.canvasMapBounds = [this.size * 0.1, this.size * 0.1, this.size * 0.8, this.size * 0.8];
    }
  },
  async mounted() {
    // const junction_font = new FontFace('Lily-Path-Name', 'url(/fonts/LilyPathName.ttf)');
    const junction_font2 = new FontFace('ChenYuluoyan', 'url(/assets/fonts/ChenYuluoyan.ttf)');
    const loaded_face = await junction_font2.load();
    document.fonts.add(loaded_face);
    document.fonts.ready.then(() => {
      this.loadAllImagesPromise().then(() => {
        this.init();
      });
    });
    
  },
  watch: {
    journey() {
      this.init();
    },
    dayIndex() {
      this.drawOnCanvas();
    },
  },
  computed: {
    dayAcceptIndexRange() {
      if (this.dayIndex === -1) {
        return [0, 9999];
      }
      const startIndex = this.dayIndex === 0? 0: this.journey.journeySplitIndexs[this.dayIndex - 1];
      const endIndex = this.dayIndex === this.journey.journeySplitIndexs.length?9999:this.journey.journeySplitIndexs[this.dayIndex];
      return [startIndex, endIndex];
    },
    realRotateAngle() {
      if (this.needRotate) {
        return this.rotateAngle;
      } else {
        return 0;
      }
    },
    drawTitle() {
      if (this.title.length > 0) {
        return this.title;
      }
      return this.journey.name;
    }
  },
  methods: {
    async loadAllImagesPromise() {
      this.imgElements.splice(0, this.imgElements.length);
      const allImgs = await Promise.all(iconImgs.map((src) => {
        return this.getImgElementPromise(src);
      }));
      for (const img of allImgs) {
        this.imgElements.push(img);
      }
    },
    getImgElementPromise(src) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = function() {
          resolve(this);
        };
        image.src = src;
        setTimeout(() => {
          reject('載入逾時');
        }, 5000);
      });
    },
    async init() {
      try {
        this.canvasSizeInit();
        this.parseCanvasPoints();
        this.drawOnCanvas();

        this.inited = true;
      } catch (e) {
        console.error(e.toString());
      } finally {
        this.$emit('inited', {milestones: this.normalMilestones, days: this.journey.journeySplitIndexs.length + 1});
      }
    },
    canvasSizeInit() {
      console.log('inited');
      const dpr = window.devicePixelRatio;
      // const rect = this.$refs.mapCanvas.getBoundingClientRect();
      const ctx = this.$refs.mapCanvas.getContext('2d');

      this.canvasSize.width = this.canvasStyleSize.width * dpr;
      this.canvasSize.height = this.canvasStyleSize.height * dpr;
      this.$refs.mapCanvas.width = this.canvasSize.width;
      this.$refs.mapCanvas.height = this.canvasSize.height;

      // this.$refs.mapCanvas.style.width = `${this.canvasStyleSize.width}px`;
      // this.$refs.mapCanvas.style.height = `${this.canvasStyleSize.height}px`;
      ctx.scale(dpr, dpr);
    },
    __normalizeCanvasPoints() {
      this.normalMilestones.splice(0, this.normalMilestones.length);
      this.normalRoutes.splice(0, this.normalRoutes.length);
      this.cxMax = -1;
      this.cyMax = -1;
      this.cxMin = 360;
      this.cyMin = 180;

      const temp = [];
      const tempM = [];

      for (let ind = 0; ind < this.journey.journey.length; ind++) {
        const j = this.journey.journey[ind];
        if (j.type === "route") {
          const {detail} = j;
          const pts = [];
          for (let i=0;i<detail.routepts.length;i++) {
            let pt = null;
            if (j.direction === 0) {
              pt = detail.routepts[i];
            } else {
              pt = detail.routepts[detail.routepts.length - 1 - i];
            }
            const x = pt.lng + 180;
            const y = 90 - pt.lat;
            this.cxMax = Math.max(this.cxMax, x);
            this.cyMax = Math.max(this.cyMax, y);
            this.cxMin = Math.min(this.cxMin, x);
            this.cyMin = Math.min(this.cyMin, y);
            pts.push({
              x, y
            });
          }
          temp.push({
            pts,
            oriIndex: ind,
          });
        } else if (j.type === "milestone") {
          const {detail} = j;
          const mType = this.__calcType(detail);
          if (!this.hideBlankMilestones || mType > 0) {
            tempM.push({
              serial: detail.serial,
              x: detail.lng + 180,
              y: 90 - detail.lat,
              name: detail.name,
              type: mType,

              distance: 0,
              angle: 0,
              oriIndex: ind,
            });
          }
        }
      }

      if (this.needRotate) {
        this.__calcRotateAngle(tempM);
        const cx = (this.cxMin + this.cxMax) / 2.0;
        const cy = (this.cyMin + this.cyMax) / 2.0;

        this.cxMax = -999;
        this.cyMax = -999;
        this.cxMin = 999;
        this.cyMin = 999;
        for (let i=0;i<temp.length;i++) {
          for (const r of temp[i].pts) {
            const newPt = this.__ptRotate(cx, cy, r.x, r.y, this.rotateAngle);
            r.x = newPt[0];
            r.y = newPt[1];

            this.cxMax = Math.max(this.cxMax, r.x);
            this.cyMax = Math.max(this.cyMax, r.y);
            this.cxMin = Math.min(this.cxMin, r.x);
            this.cyMin = Math.min(this.cyMin, r.y);
          }
        }
        for (let i=0;i<tempM.length;i++) {
          const m = tempM[i];
          const newPt = this.__ptRotate(cx, cy, m.x, m.y, this.rotateAngle);
          m.x = newPt[0];
          m.y = newPt[1];
        }

        console.log(this.rotateAngle);
      }

      const mapW = 1;
      const mapH = 1;
      const pathW = Math.abs(this.cxMax - this.cxMin);
      const pathH = Math.abs(this.cyMax - this.cyMin);
      const scaleX = mapW / pathW, scaleY = mapH / pathH;
      const scale = scaleX < scaleY ? scaleX: scaleY;
      const center = {x: (this.cxMax + this.cxMin) / 2, y: (this.cyMax + this.cyMin) /2};
      for (const route of temp) {
        const pts = [];
        for (const pt of route.pts) {
          const x = (pt.x - center.x) * scale * 2;
          const y = (pt.y - center.y) * scale * 2;
          pts.push({x, y});
        }
        this.normalRoutes.push({
          pts,
          oriIndex: route.oriIndex,
        });
      }

      for (const m of tempM) {
        const x = (m.x - center.x) * scale * 2;
        const y = (m.y - center.y) * scale * 2;
        this.normalMilestones.push({
          serial: m.serial,
          x,
          y,
          name: m.name,
          type: m.type,

          distance: m.distance,
          angle: m.angle,
          oriIndex: m.oriIndex,
        });
      }
      
    },
    parseCanvasPoints() {
      this.__normalizeCanvasPoints();
      this.drawRoutes.splice(0, this.drawRoutes.length);
      this.normalRoutes.map((item) => {
        this.drawRoutes.push(item);
      });
      this.drawMilestones.splice(0, this.drawMilestones.length);
      this.normalMilestones.map((item) => {
        this.drawMilestones.push(item);
      });
    },
    __getMilestoneConfig(milestoneSerial) {
      if (this.config !== undefined) {
        const search = this.config.milestoneTitlePos.filter((c) => {
          return c.milestoneSerial === milestoneSerial;
        })
        if (search.length === 1) {
          return search[0];
        }
      }
      return {
        transformX: 0,
        transformY: 0,
        circleTransformX: 0,
        circleTransformY: 0,
      };
    },
    __ptRotate(cx, cy, x, y, radians) {
      const cos = Math.cos(radians);
      const sin = Math.sin(radians);
      const nx = (cos * (x - cx)) + (sin * (y - cy)) + cx;
      const ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
      return [nx, ny];
    },
    __drawLogo(ctx) {
      ctx.save();
      ctx.drawImage(this.imgElements[9], this.size * (0.5 - (( 0.1135 / 2) / 2)), this.size * 0.025, this.size * 0.1135 / 2, this.size * 0.05 / 2);

      ctx.restore();
    },
    __drawSummaryInfo(ctx) {
      ctx.save();

      // ctx.font = "60px Lily-Path-Name";
      ctx.strokeStyle = "#000";
      ctx.textAlign = "center";
      ctx.fillText(this.drawTitle, this.canvasStyleSize.width / 2, this.size * 0.15);

      let totalTime = 0, totalDistance = 0, totalUp = 0, totalDown = 0, lastHeight = -1;
      for (let i=0;i<this.journey.journey.length;i++) {
        const j = this.journey.journey[i];
        if (j.type === 'route') {
          totalTime += (j.endTimestamp - j.startTimestamp) / 1000;
          totalDistance += j.detail.distance;

        } else if(j.type === 'milestone') {
          if (lastHeight > 0) {
            const heightDistance = j.detail.height - lastHeight;
            if (heightDistance > 0) {
              totalUp += heightDistance;
            } else {
              totalDown -= heightDistance;
            }
          }
          lastHeight = j.detail.height;
        }
      }

      ctx.font = `${this.size * 0.035}px ChenYuluoyan,arial,sans-serif`;
      ctx.textAlign = "left";

      const l = 0.18;
      const l2 = l + 0.025;
      ctx.drawImage(this.imgElements[10], this.size * 0.15, this.size * l, this.size * 0.03, this.size * 0.03);
      ctx.fillText(`${Math.floor(totalTime / 3600)} h ${Math.floor(totalTime / 60)%60} min`, this.size * 0.19 , this.size * l2);
      ctx.drawImage(this.imgElements[13], this.size * 0.33, this.size * l, this.size * 0.03, this.size * 0.03);
      ctx.fillText(`${(totalDistance / 1000).toFixed(1)} km`, this.size * 0.37 , this.size * l2);
      ctx.drawImage(this.imgElements[11], this.size * 0.51, this.size * l, this.size * 0.03, this.size * 0.03);
      ctx.fillText(`${totalUp} m`, this.size * 0.55 , this.size * l2);
      ctx.drawImage(this.imgElements[12], this.size * 0.69, this.size * l, this.size * 0.03, this.size * 0.03);
      ctx.fillText(`${totalDown} m`, this.size * 0.73 , this.size * l2);
      
      ctx.restore();
    },
    __drawTitleOnCanvas(ctx) {
      if (this.showInfo) {
        ctx.save();

        ctx.font = `${this.size * 0.1}px ChenYuluoyan`;

        const len = ctx.measureText(this.drawTitle).width;
        ctx.save();
        ctx.beginPath();
        ctx.rect((this.size - len) / 2, this.size * 0.13, len, this.size * 0.025);
        ctx.fillStyle = '#F7DE5B';
        ctx.fill();
        ctx.restore();

        this.__drawSummaryInfo(ctx);

        ctx.restore();
      }
    },
    __drawRouteOnCanvas(ctx) {
      ctx.save();
      ctx.lineCap = 'round';
      ctx.lineJoin = "round";
      ctx.lineWidth = this.pathWidth;
      ctx.strokeStyle = this.pathColor;

      // ctx.beginPath();
      // ctx.moveTo(-0.9, 0.7);
      // ctx.lineTo(0.5, 0.7);
      // ctx.lineTo(-0.5, 0.9);
      // ctx.lineTo(0.9, 0.9);
      // ctx.stroke();

      const range = this.dayAcceptIndexRange;

      for (const route of this.drawRoutes) {
        const res = route.oriIndex >= range[0] && route.oriIndex <= range[1];
        if (res) {
          ctx.globalAlpha = 1;
          // ctx.strokeStyle = this.pathColor;
        } else {
          ctx.globalAlpha = 0.2;
          // ctx.strokeStyle = '#000000';
        }
        ctx.moveTo(route.pts[0].x, route.pts[0].y);
        ctx.beginPath();
        for (const pt of route.pts) {
          ctx.lineTo(pt.x, pt.y);
        }
        ctx.stroke();
      }
      ctx.restore();
    },
    __drawSingleMilestone(ctx, milestone) {
      const config = this.__getMilestoneConfig(milestone.serial);

      ctx.save();
      const range = this.dayAcceptIndexRange;
      const res = milestone.oriIndex >= range[0] && milestone.oriIndex <= range[1];
      if (res) {
        ctx.globalAlpha = 1;
      } else {
        ctx.globalAlpha = 0.2;
      }

      const circleW = 0.04;

      if (milestone.type !== 0 && milestone.type !== 8) {
        let imgSrc = null;
        switch(milestone.type) {
          case 1:
          imgSrc = this.imgElements[4];
          break;

          case 2:
          imgSrc = this.imgElements[5];
          break;

          case 3:
          imgSrc = this.imgElements[2];
          break;

          case 4:
          imgSrc = this.imgElements[1];
          break;

          case 5:
          imgSrc = this.imgElements[0];
          break;

          case 6:
          imgSrc = this.imgElements[6];
          break;

          case 7:
          imgSrc = this.imgElements[3];
          break;

          case 8:
          break;
        }
        ctx.translate(milestone.x + config.circleTransformX, milestone.y + config.circleTransformY);
        ctx.beginPath();
        ctx.arc(0, 0, circleW, 0, Math.PI * 2);
        ctx.fillStyle = this.pathColor;
        ctx.fill();
        if (imgSrc !== null) {
          ctx.drawImage(imgSrc, - circleW / 2, - circleW / 2, circleW, circleW);
        }
      } else {
        ctx.translate(milestone.x + config.circleTransformX, milestone.y + config.circleTransformY);
        ctx.beginPath();
        ctx.arc(0, 0, circleW * 0.66 , 0, Math.PI * 2);
        ctx.fillStyle = this.pathColor;
        ctx.fill();

        ctx.beginPath();
        ctx.arc(0, 0, circleW * 0.3 , 0, Math.PI * 2);
        ctx.fillStyle = '#fff';
        ctx.fill();
      }
      ctx.restore();

      ctx.save();
      ctx.translate(milestone.x + config.transformX, milestone.y + config.transformY);
      // ctx.font = "0.1px ChenYuluoyan,arial,sans-serif";
      ctx.scale(0.1, 0.1);
      ctx.font = "1px ChenYuluoyan";
      ctx.fillStyle = this.milestoneTextColor;
      ctx.textAlign = "center";
      ctx.fillText(milestone.name, 0, 0);
      ctx.restore();
    },
    __drawMilestonesOnCanvas(ctx) {
      ctx.save();
      for (const m of this.drawMilestones) {
        this.__drawSingleMilestone(ctx, m);
      }
      ctx.restore();
    },
    drawOnCanvas() {
      const ctx = this.$refs.mapCanvas.getContext('2d');
      ctx.clearRect(0, 0, this.canvasSize.width, this.canvasSize.height);

      if (this.showInfo) {
        ctx.save();
        ctx.fillStyle = '#fff';
        ctx.fillRect(0, 0, this.canvasSize.width, this.canvasSize.height);
        ctx.restore();
      }

      if (this.showLogo) {
        this.__drawLogo(ctx);
      }
      this.__drawTitleOnCanvas(ctx);
      // draw map
      ctx.save();
      ctx.translate(this.canvasMapBounds[0] + (this.canvasMapBounds[2] / 2), this.canvasMapBounds[1] + (this.canvasMapBounds[3] / 2));
      const scale = (Math.min(this.canvasMapBounds[2], this.canvasMapBounds[3])) / 2;
      ctx.scale(scale, scale);
      // ctx.rotate(this.realRotateAngle);

      // ctx.save();
      // ctx.beginPath();
      // ctx.lineWidth = 0.001;
      // ctx.rect(-1, -1, 2, 2);
      // ctx.stroke();
      // ctx.restore();

      this.__drawRouteOnCanvas(ctx);
      this.__drawMilestonesOnCanvas(ctx);
      ctx.restore();
    },
    __calcType(m) {
      if (m.triangle) {
        return 5;
        // return this.$refs.mountain;
      }
      if (m.parking) {
        return 4;
        // return this.$refs.parking;
      }
      if (m.entrance) {
        return 2;
        // return this.$refs.entrance;
      }
      if (m.house) {
        return 3;
        // return this.$refs.house;
      }
      if (m.waterspot !== 0) {
        return 6;
        // return this.$refs.water;
      }
      if (m.campable) {
        return 1;
        // return this.$refs.camp;
      }
      if (m.toilet) {
        return 7;
      }
      if (m.spot) {
        return 8;
      }
      return 0;
      // return null;

    },
    __calcRotateAngle(ms) {
      let maxDis = 0;
      let maxDisPts = [0, 0, 0, 0];
      for (let i=0;i<ms.length -1;i++) {
        const pt1 = {
          x: ms[i].x,
          y: ms[i].y,
        };
        for (let j=i+1; j<ms.length;j++) {
          const pt2 = {
            x: ms[j].x,
            y: ms[j].y,
          };
          const dis = this.__ptDis(pt1, pt2);
          if (dis > maxDis) {
            maxDisPts[0] = pt1.x;
            maxDisPts[1] = pt1.y;
            maxDisPts[2] = pt2.x;
            maxDisPts[3] = pt2.y;
            maxDis = dis;
          }
        }
      }
      console.log(maxDisPts);

      this.rotateAngle = Math.atan2(maxDisPts[3] - maxDisPts[1], maxDisPts[2] - maxDisPts[0]);
    },
    __ptDis(pt1, pt2) {
      return Math.pow(Math.pow(pt2.x - pt1.x, 2) + Math.pow(pt2.y - pt1.y, 2), 0.5);
    },
    async toBlobAsync() {
      const makeImagePromise = async () => {
        let blob = await new Promise(resolve => this.$refs['mapCanvas'].toBlob(resolve));
        return await blob;
      }
      return await makeImagePromise();
    },
    toDataURL() {
      return this.$refs['mapCanvas'].toDataURL("image/png");
    },
  }

}
</script>

<style scoped>
  .pathTitle {
    font-family: Lily-Path-Name;
    text-align: center;
    font-size: 3rem;
  }

  .route-canvas {
    text-align: center;
    /* border: solid 1px #0004; */
  }
</style>
